window.CESIUM_BASE_URL = '/';

import * as Cesium from 'cesium/Build/CesiumUnminified/Cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';

Cesium.Ion.defaultAccessToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyN2I1N2FiMC05NGUzLTQ0NmYtYTU3ZC03ODg1N2YyMTgyNDkiLCJpZCI6NjIyNzksImlhdCI6MTYyNjg3NjAyNX0.m9pL12dTVZecSmOXHlh2Bi0SonD51nlvJIzn5AE0h50';

class CameraProps {
  constructor(heading, pitch) {
    this.heading = Cesium.Math.toRadians(heading);
    this.pitch = Cesium.Math.toRadians(pitch);
  }
}

class IglooCubemapViewer {
  #ninetyDegrees = Cesium.Math.toRadians(90);

  #defaultOptions = {
    timeline: false,
    infoBox: false,
    vrButton: false,
    fullscreenButton: false,
    navigation: false,
    homeButton: false,
    geocoder: false,
    animation: false,
    sceneModePicker: false,
    selectionIndicator: false,
    navigationHelpButton: false,
    baseLayerPicker: false,
  };

  constructor(opts, otherOpts) {
    let options;

    const style = document.createElement('style');
    style.innerHTML = `
      body {
        display: flex !important;
        height: 100% !important;
        width: 100% !important;
        margin: 0;
        padding: 0;
        position: absolute !important;
      }

      #front,
      #left,
      #right,
      #back,
      #top,
      #bottom {
        width: 16.6666666667%;
        height: 100%;
      }

      #blank {
        display: none;
      }

      .cesium-viewer-bottom {
        display: none !important;
      }
      `;

    document.head.appendChild(style);

    if (typeof opts === 'string') {
      options = otherOpts;
      document.getElementById(opts).remove();
    } else {
      options = opts;
    }

    this.__viewers = {};

    ['left', 'front', 'right', 'back', 'top', 'bottom'].forEach(face => {
      const div = document.createElement('div');
      div.id = face;
      document.body.appendChild(div);

      this.__viewers[face] = new Cesium.Viewer(face, { ...this.#defaultOptions, ...options });
      this.__viewers[face].__direction__ = face;
    });

    this.viewer = this.__viewers.left;

    this.forEachFace = this.forEachFace.bind(this);

    this.forEachFace(face => {
      face.camera.frustum.fov = this.#ninetyDegrees;

      face.camera.percentChanged = 0;

      face.camera.changed.addEventListener(() => {
        const destination = face.camera.positionWC;
        this.forEachFace(face => {
          face.camera.setView({ destination });
        });
        face.camera.setView({ orientation: IglooCubemapViewer.cameraProps[face.__direction__] });
      });
    });
  }

  static cameraProps = {
    left: new CameraProps(270, 0),
    front: new CameraProps(0, 0),
    right: new CameraProps(90, 0),
    back: new CameraProps(180, 0),
    top: new CameraProps(0, 90),
    bottom: new CameraProps(0, -90),
  };

  forEachFace(face) {
    Object.values(this.__viewers).forEach(e => face(e));
  }
}

const terrainProvider = Cesium.createWorldTerrain();

const { viewer, forEachFace } = new IglooCubemapViewer({ terrainProvider });

const url = new URL(location.href);
const sp = url.searchParams;
const lat = Number(sp.get('lat')) || 51.5044286;
const lng = Number(sp.get('lng')) || -0.0874995;
const buildings = sp.get('buildings') ? !!Number(sp.get('buildings')) : true;

forEachFace(viewer => {
  if (buildings) viewer.scene.primitives.add(Cesium.createOsmBuildings());
});

setTimeout(() => {
  viewer.camera.flyTo({
    destination: Cesium.Cartesian3.fromDegrees(lng, lat, 100),
    duration: 0,
  });
}, 0);
